body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






.montserrat-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}

@keyframes slideUpImage {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes slideUpContent {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownContent {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes slideUpBackground {
  0% {
    background-position: center bottom;
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    background-position: center top;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownBackground {
  0% {
    background-position: center top;
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    background-position: center bottom;
    opacity: 0;
    transform: translateY(100%);
  }
}

.react-multiple-carousel__arrow--left {
  left: calc(-1% + 1px) !important;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: transparent !important;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.react-multiple-carousel__arrow--right {
  right: calc(-2% + 1px) !important;
}



@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideUp {
  animation: slideUp 0.5s ease-in-out;
  font-size: 16px;
  padding: 10px;
}

/* Custom scrollbar styles */
.custom-scrollbar {
  overflow-y: auto; /* Ensure the div is scrollable */
  max-height: 300px; /* Adjust height as needed */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the thumb */

}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}
.react-multiple-carousel__arrow--left::before {
  content: "" !important; /* Remove original content */
    background-image: url('./Assets/arrow1.png'); /* Your icon path */
    background-size: contain; /* Scale the image */
    background-repeat: no-repeat; /* Prevent repeat */
    display: block; /* Make it block to occupy space */
    width: 40px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    position: absolute; /* Positioning to place it correctly */

}

.react-multiple-carousel__arrow--right::before {
  content: "" !important; /* Remove original content */
    background-image: url('./Assets/arrow2.png'); /* Your icon path */
    background-size: contain; /* Scale the image */
    background-repeat: no-repeat; /* Prevent repeat */
    display: block; /* Make it block to occupy space */
    width: 40px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    position: absolute; /* Positioning to place it correctly */
}